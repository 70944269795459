'use client';

import React, { Component } from 'react';

import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';

interface IProps {
	fallback: React.ReactNode;
	children: React.ReactNode;
}

interface IState {
	hasError: boolean;
	error: any | undefined;
}

export class ErrorBoundary extends Component<IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false, error: undefined };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error };
	}

	componentDidCatch(error: unknown, info: unknown) {
		console.error(error, info);
		// Example "componentStack":
		//   in ComponentThatThrows (created by App)
		//   in ErrorBoundary (created by App)
		//   in div (created by App)
		//   in App
		// logErrorToMyService(error, info.componentStack);
	}

	render() {
		const {
			error: {
				errorMessageObj = undefined,
			} = {},
			hasError,
		} = this.state;

		if (hasError) {
			if (errorMessageObj) {
				return <PromiseErrorMessaging errorMessage={errorMessageObj} />;
			}
			// You can render any custom fallback UI
			return this.props.fallback;
		}

		return this.props.children;
	}
}
