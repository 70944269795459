'use client';

import { observer } from 'mobx-react';
import React, { type MouseEvent } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';

import styles from '~/global/footer/Components/FooterCartId.module.scss';

export const FooterCartId = observer(() => {
	const {
		globalDynamicModel: {
			cartNumber = '',
		} = {}
	} = useGlobalContext();

	const selectFieldValue = (event: MouseEvent<HTMLInputElement>) => {
		const field = event.currentTarget;

		field.setSelectionRange(0, field.value.length);

		field.focus();
	};

	if (!cartNumber) {
		return null;
	}

	return (
		<div className={styles['footer-cart-id']}>
			<label
				className={styles['footer-cart-id-label']}
				data-qa="footer-cart-id"
			>
				<span className="tw-sr-only">Internal use only </span>Cart ID:
				<input
					className={styles['footer-cart-id-input']}
					id="selectable-cart-id"
					data-qa="selectable-cart-id"
					onClick={selectFieldValue}
					readOnly={true}
					value={cartNumber}
				/>
			</label>
		</div>
	);
});

// eslint-disable-next-line import/no-default-export
export default FooterCartId;
